import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card, CircularProgress } from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";
import Transitions from "@material-ui/core/styles/transitions";
import withGlobalPortal from "../portals/withGlobalPortal";

const baseZIndex = zIndex.snackbar - 10;

const portalEnhancer = useSheet({
  overlay: {
    display: "none",
    "& > div": {
      position: "fixed",
      zIndex: baseZIndex + 20,
      top: 0,
      left: "50%",
      transform: "translate3d(-50%, -200%, 0) scale(0.5)",
      transition: Transitions.create("300ms", "transform"),
    },
  },
  overlayShown: {
    display: "block",
    zIndex: 2,
    // zIndex: baseZIndex + 10,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "& .MuiPaper-root": {
      zIndex: 3,
    },
    "& > div": {
      position: "fixed",
      zIndex: 3,
      top: "40%",
      left: "50%",
      transform: "translate3d(-50%, 10%, 0) scale(1)",
      transition: "all .3s ease",
      padding: "3rem",
    },
  },
});

const PageLoadingPortal = portalEnhancer((props) => (
  <div
    className={props.show ? props.classes.overlayShown : props.classes.overlay}
  >
    <Card>
      <CircularProgress color="secondary" variant="indeterminate" />
    </Card>
  </div>
));

const enhancer = compose(
  withGlobalPortal(
    fp.flow(fp.find(fp.isMatch({ isLoading: true })), (show) => (
      <PageLoadingPortal show={show} />
    )),
  ),
);

PageLoading.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
};

function PageLoading(props) {
  return React.Children.count(props.children)
    ? React.Children.only(props.children)
    : null;
}

export default enhancer(PageLoading);
