import React from "react";
import useSheet from "react-jss";
import { compose, setDisplayName } from "recompose";
import PropTypes from "prop-types";
import spring from "react-motion/lib/spring";
import Motion from "react-motion/lib/Motion";
import { accent1, danger1, primary1 } from "../../theme/main-theme";

const size = 96;

const createLoader = ({ offset, duration, ...style }) => ({
  ...style,
  position: "absolute",
  top: `${offset}px`,
  left: `${offset}px`,
  right: `${offset}px`,
  bottom: `${offset}px`,
  borderRadius: "50%",
  willChange: "transform",
  border: "1px solid transparent",
  transform: "translate3d(0, 0, 0)",
  animationName: "rotate",
  animationIterationCount: "infinite",
  animationDuration: `${duration}s`,
  animationTimingFunction: "cubic-bezier(0.4, 0.0, 0.6, 1)",
});

const enhancer = compose(
  useSheet(
    {
      "@keyframes rotate": {
        "0%": { transform: "rotate(0deg)" },
        "100%": { transform: "rotate(360deg)" },
      },
      "@keyframes scale": {
        "0%": { transform: "scale3d(1, 1, 1)" },
        "50%": { transform: "scale3d(0.9, 0.9, 0.9)" },
        "100%": { transform: "scale3d(1, 1, 1)" },
      },

      "@keyframes priceAnimation": {
        from: { backgroundPosition: "0 100%" },
        to: { backgroundPosition: "-900px 100%" },
      },
      "@keyframes priceCarAnimation": {
        "0%": { transform: "translatey(0)" },
        "50%": { transform: "translatey(1px)" },
        "100%": { transform: "translatey(0)" },
      },
      "@-webkit-keyframes cssProgressActive": {
        "0%": {
          backgroundPosition: "0 0",
        },
        "100%": {
          backgroundPosition: "35px 35px",
        },
      },
      "@keyframes cssProgressActive": {
        "0%": {
          backgroundPosition: "0 0",
        },
        "100%": {
          backgroundPosition: "35px 35px",
        },
      },
      "@-webkit-keyframes cssProgressActiveRight": {
        "0%": {
          backgroundPosition: "0 0",
        },
        "100%": {
          backgroundPosition: "-35px -35px",
        },
      },
      "@keyframes cssProgressActiveRight": {
        "0%": {
          backgroundPosition: "0 0",
        },
        "100%": {
          backgroundPosition: "-35px -35px",
        },
      },
      overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10000,
        position: "fixed",
        backgroundColor: "#f5f5f5",
        alignItems: "center",
        justifyContent: "center",
      },
      wrapper: {
        width: `${size}px`,
        height: `${size}px`,
        position: "relative",
      },
      rotateWrapper: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: "absolute",
        transform: "translate3d(0, 0, 0)",
        animation: "rotate 2s cubic-bezier(0.4, 0.0, 0.6, 1) infinite",
        "&:hover": { animationPlayState: "paused" },
      },
      line1: createLoader({ offset: 0, duration: 2, borderLeftColor: danger1 }),
      line2: createLoader({
        offset: 4,
        duration: 3,
        borderRightColor: accent1,
      }),
      line3: createLoader({
        offset: 8,
        duration: 5,
        borderBottomColor: primary1,
      }),
      icon: {
        top: "16px",
        left: "16px",
        right: "16px",
        bottom: "16px",
        position: "absolute",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        // backgroundImage: "url(/favicon.ico)",
        willChange: "transform, filter",
        transform: "translate3d(0, 0, 0)",
        animation: "scale 1.5s linear infinite",
      },
    },
    { important: false },
  ),
  setDisplayName("PreloaderPortal"),
);

PreloaderPortal.propTypes = {
  sheet: PropTypes.object,
  show: PropTypes.bool.isRequired,
};

const createStyles = (show) => ({ opacity: spring(show ? 1 : 0) });
const getStyles = (style) => ({
  opacity: style.opacity,
  display: style.opacity === 0 ? "none" : "flex",
});

function PreloaderPortal(props) {
  const {
    sheet: { classes },
  } = props;

  return (
    <Motion style={createStyles(props.show)}>
      {(style) => (
        <div style={getStyles(style)} className={classes.overlay}>
          <div className={classes.wrapper}>
            <div className={classes.icon} />
            <div className={classes.rotateWrapper}>
              <div className={classes.line1} />
              <div className={classes.line2} />
              <div className={classes.line3} />
            </div>
          </div>
        </div>
      )}
    </Motion>
  );
}

export default enhancer(PreloaderPortal);
