import { Map, fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import {
  createAsyncReducer,
  createAsyncFlagReducer,
} from "../helpers/ReducerFactory";
import { injectReducer } from "../../shared/helpers/ReducerContext";

export const FETCH_CUSTOMER_ACCOUNT = "CUSTOMER_ACCOUNT/FETCH_CUSTOMER_ACCOUNT";
export const UPDATE_CUSTOMER_ACCOUNT =
  "CUSTOMER_ACCOUNT/UPDATE_CUSTOMER_ACCOUNT";

const selector = injectReducer(
  "customer",
  combineReducers({
    item: createAsyncReducer(
      FETCH_CUSTOMER_ACCOUNT,
      Map({ data: null, failed: false }),
      {
        pending: state => state.merge({ failed: false }),
        fulfilled: (state, action) =>
          state.merge({
            failed: false,
            data: fromJS(action.payload.data.data),
          }),
        rejected: state => state.merge({ data: null, failed: true }),
      },
    ),
    updating: createAsyncFlagReducer(UPDATE_CUSTOMER_ACCOUNT),
  }),
);

export function getCustomer(state) {
  return selector(state).getIn(["item", "data"]);
}

export function getCustomerCompany(state) {
  return selector(state).getIn(["item", "data", "company"]);
}

export function getCustomerId(state) {
  return selector(state).getIn(["item", "data", "customer_id"]);
}
