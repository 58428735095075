import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";
import sprintf from "sprintf";

const NOTIFICATION_PUSH_LOGS_URL = `${API_ROOT_URL}/admin/push_logs`;
const UPDATE_NOTIFICATION_PUSH_LOGS_URL = `${API_ROOT_URL}/admin/push_log/status`;
const NOTIFICATION_PUSH_LOG_ITEM_URL = `${API_ROOT_URL}/admin/push_log/%s`;

export const getPushLogs = (status, type) =>
  api.get(NOTIFICATION_PUSH_LOGS_URL, {
    params: {
      status,
      type,
    },
  });

export const getPushItem = id =>
  api.get(sprintf(NOTIFICATION_PUSH_LOG_ITEM_URL, id));

export const updatePushItem = id =>
  api.put(sprintf(NOTIFICATION_PUSH_LOG_ITEM_URL, id), {
    body: {
      status: "VIEWED",
    },
  });

export const updateMultiplePushLog = ids =>
  api.post(UPDATE_NOTIFICATION_PUSH_LOGS_URL, {
    body: {
      ids,
      status: "VIEWED",
    },
  });
