import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import { CASH_CLIENTS, MERCHANTS } from "../../constants/MerchantTypes";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const CustomerAutoComplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(({ input: { value } }) =>
      value && value.id && !value.name
        ? getCachedCustomer(value.id)
            .map(item => ({ id: value.id, name: item.get("full_name") }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .distinctUntilChanged(
      fp.overEvery([
        isEqualDataIn("input.value.name"),
        isEqualDataIn("merchantType"),
      ]),
    )
    .switchMap(({ merchantType, input: { value } }) =>
      value && value.name
        ? getCustomerPredictions(
            baseFilter
              .setValue(
                "merchant_type",
                merchantType ? MERCHANTS : CASH_CLIENTS,
              )
              .setSearch(value && value.name),
          )
            .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
            .catch(() => Observable.of(List()))
        : Observable.of(List()),
    )
    .distinctUntilChanged(isEqualData)
    .map(v => v.toJS())
    .startWith([]);

  return propsStream.combineLatest(
    valueStream,
    optionsStream,
    (
      { readOnly, parseInput, formatOption, merchantType, ...props },
      value,
      options,
    ) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={formatOption}
        input={{ ...props.input, value }}
      />
    ),
  );
});

FormCustomerWithMerchantsAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  placeholder: PropTypes.string,
  label: PropTypes.node,
};

FormCustomerWithMerchantsAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormCustomerWithMerchantsAutoComplete(props) {
  return <Field {...props} component={CustomerAutoComplete} />;
}
