// import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Observable } from "rxjs";
import { Iterable } from "immutable";
import fp from "lodash/fp";
import { componentFromStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { isEqualData } from "../../helpers/DataUtils";
import { withStyles } from "@material-ui/core/styles";

const CssAutocomplete = withStyles({
  root: {
    "& .Mui-disabled>fieldset": {
      borderStyle: "dashed",
    },
  },
})(Autocomplete);

export const AutoCompleteComponent = componentFromStream(propsStream => {
  const { handler: nodeRef, stream: nodeRefStream } = createEventHandler();
  const {
    handler: onNewRequest,
    stream: onNewRequestStream,
  } = createEventHandler();
  const {
    handler: onUpdateInput,
    stream: onUpdateInputStream,
  } = createEventHandler();

  const sideEffectsStream = Observable.merge(
    onNewRequestStream
      .map(fp.get("value"))
      .withLatestFrom(propsStream)
      .do(([value, props]) => props.input.onChange(props.parseOption(value))),

    onUpdateInputStream
      .filter(fp.flow(fp.nth(2 /* options argument */), fp.isEqual("input")))
      .map(fp.nth(1 /* searchText argument */))
      .withLatestFrom(propsStream)
      .do(([searchText, props]) => {
        props.input.onChange(props.parseInput(searchText));
      }),
    propsStream
      .map(fp.flow(fp.get("focus"), Boolean))
      .distinctUntilChanged()
      .filter(Boolean)
      .combineLatest(
        nodeRefStream.map(fp.get(["refs", "searchTextField", "input"])),
      )
      .delay(100)
      .do(([, input]) => input.focus()),
  )
    .mapTo(null)
    .startWith(null)
    .distinctUntilChanged();

  const dataSourceStream = propsStream
    .distinctUntilKeyChanged("options", isEqualData)
    .map(props => {
      const dataSource = [];

      props.options.forEach(item => {
        const optionText = props.formatOption(item);
        const inputText = props.formatInput
          ? props.formatInput(item)
          : optionText;
        dataSource.push({
          name: inputText,
          value: item,
        });
      });
      return dataSource;
    });

  return propsStream
    .map(
      fp.omit([
        "optionStyle",
        "options",
        "focus",
        "parseInput",
        "parseOption",
        "renderOptionLeftIcon",
        "renderOptionRightIcon",
      ]),
    )
    .distinctUntilChanged(isEqualData)
    .combineLatest(dataSourceStream, sideEffectsStream, (props, dataSource) => {
      const {
        input,
        meta,
        formatInput,
        formatOption,
        menuProps,
        popoverProps,
        label,
        hintText,
        variant,
        size,
        filter,
        fullWidth,
        maxSearchResults,
        renderOption,
        autoSelectOneOption,
        disableClearable,
        margin,
        immediatelyShowError,
        autoFocus,
        customError,
        ignoreError,
        inputRef,
        onKeyDown,
        ...custom
      } = props;
      if (
        autoSelectOneOption &&
        !custom.disabled &&
        dataSource.length === 1 &&
        props.input.value === ""
      ) {
        onNewRequest(dataSource[0]);
      }
      // if (props.input.name==="senderPostcode")console.log(meta)
      return (
        <CssAutocomplete
          {...custom}
          disableClearable={disableClearable}
          renderOption={renderOption}
          size={size}
          ref={nodeRef}
          options={
            custom.textField
              ? _.filter(
                  dataSource,
                  item => item.name.indexOf(input.value) > -1,
                )
              : dataSource
          }
          getOptionLabel={v => fp.toString(v.name || v)}
          getOptionSelected={(option, value) =>
            option.value === (value || undefined)
          }
          onChange={(event, value) => {
            onNewRequest(value || { value });
          }}
          // value={input.value}
          value={
            // custom.clearValue
            //   ? input.value
            //   :
            fp.toString(
              input.value && formatInput
                ? formatInput(input.value)
                : formatOption(input.value),
            )
          }
          // menuCloseDelay={0}
          clearOnEscape={true}
          filterOptions={createFilterOptions({
            ignoreCase: true,
          })}
          onKeyDown={onKeyDown}
          onBlur={fp.noop}
          onFocus={fp.flow(fp.noop, input.onFocus)}
          onInputChange={fp.rest(onUpdateInput)}
          fullWidth={fullWidth}
          renderInput={params => (
            <TextField
              {...params}
              inputRef={inputRef}
              onKeyDown={onKeyDown}
              autoFocus={autoFocus}
              autocomplete="new-password"
              margin={margin}
              label={label}
              placeholder={hintText}
              value={fp.toString(
                input.value && formatInput
                  ? formatInput(input.value)
                  : formatOption(input.value),
              )}
              variant={variant}
              error={
                ignoreError
                  ? customError
                    ? true
                    : immediatelyShowError
                    ? meta.error
                    : Boolean(!meta.active && meta.touched && meta.error)
                  : ""
              }
              helperText={
                ignoreError
                  ? !customError
                    ? immediatelyShowError
                      ? meta.error
                      : !meta.active && meta.touched && meta.error
                    : customError
                  : ""
              }
            />
          )}
        />
      );
    });
});

AutoCompleteComponent.propTypes = {
  filter: PropTypes.func,
  hintText: PropTypes.node,
  label: PropTypes.node,
  renderOption: PropTypes.node,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  parseInput: PropTypes.func,
  formatInput: PropTypes.func,
  formatOption: PropTypes.func,

  optionStyle: PropTypes.object,

  renderOptionLeftIcon: PropTypes.func,
  renderOptionRightIcon: PropTypes.func,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  size: PropTypes.oneOf(["medium", "small"]),
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(Iterable),
  ]).isRequired,
  immediatelyShowError: PropTypes.bool,
  customError: PropTypes.bool,
  ignoreError: PropTypes.bool,
};

AutoCompleteComponent.defaultProps = {
  hintText: "Введите для поиска",

  openOnFocus: true,
  disableClearable: true,
  autoSelectOneOption: true,

  optionStyle: {},

  parseInput: fp.identity,
  parseOption: fp.identity,
  formatOption: fp.identity,

  renderOptionLeftIcon: fp.noop,
  renderOptionRightIcon: fp.noop,
  variant: "outlined",
  size: "small",
  ignoreError: true,
};

FormTextAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  filter: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  focus: PropTypes.bool,
  ignoreError: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  parseInput: PropTypes.func,
  parseOption: PropTypes.func,
  formatInput: PropTypes.func,
  formatOption: PropTypes.func,

  renderOptionLeftIcon: PropTypes.func,
  renderOptionRightIcon: PropTypes.func,
  inputRef: PropTypes.func,
  onKeyDown: PropTypes.func,
  ignoreTextField: PropTypes.bool,
  autoFocus: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(Iterable),
  ]).isRequired,
};

export default function FormTextAutoComplete(props) {
  if (props.ignoreTextField) {
    return (
      <Field {...props} textField={false} component={AutoCompleteComponent} />
    );
  }
  return (
    <Field {...props} textField={true} component={AutoCompleteComponent} />
  );
}
