import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Card, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { getAppLogo } from "../../../shared/reducers/AppReducer";
import Logo from "../../assets/logo_uzpost.png";

const cleanupProps = fp.omit([
  "sheet",
  "classes",
  "children",
  "className",
  "cardClassName",
  "withLogo",
  "isLoading",
  "zDepth",
  "loadingZDepth",
]);

const enhancer = compose(
  connect(state => ({
    appLogo: getAppLogo(state),
  })),
  useSheet({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      display: "block",
      maxWidth: "200px",
      marginTop: "30px",
      marginLeft: "auto",
      maxHeight: "200px",
      marginRight: "auto",
      marginBottom: "30px",
      "@media (max-width: 991px)": {
        maxWidth: "100px",
      },
    },
    card: {
      important: false,
      position: "relative",
      minWidth: `${96 * 5}px`,
      maxWidth: `${96 * 5}px`,
    },
    cardLoading: {
      overflow: "hidden",
      minWidth: `${96 * 2}px`,
      maxWidth: `${96 * 2}px`,
      minHeight: `${96 * 2}px`,
      maxHeight: `${96 * 2}px`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    hidden: { display: "none" },
  }),
  mapPropsStream(propsStream => {
    const marketplaceStream = propsStream.map(() => window.location.host);

    return propsStream
      .combineLatest(marketplaceStream, (props, marketplaceId) => ({
        ...props,
        marketplaceId,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

CenteredCard.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  zDepth: PropTypes.number,
  loadingZDepth: PropTypes.number,
  className: PropTypes.string,
  cardClassName: PropTypes.string,
  withLogo: PropTypes.bool,
  appLogo: PropTypes.string,
  marketplaceId: PropTypes.string,
  dispatch: PropTypes.func,
};

CenteredCard.defaultProps = {
  zDepth: 1,
  isLoading: false,
  loadingZDepth: 5,
  withLogo: false,
};

function CenteredCard({ marketplaceId, appLogo, dispatch, ...props }) {
  const rootProps = cleanupProps(props);
  const logo = appLogo || Logo;

  return (
    <div className={cx(props.classes.root, props.className)}>
      <Card
        {...rootProps}
        zDepth={props.isLoading ? props.loadingZDepth : props.zDepth}
        className={cx(
          props.classes.card,
          { [props.classes.cardLoading]: props.isLoading },
          props.cardClassName,
        )}
        style={{ minWidth: "300px", width: "450px" }}
      >
        {props.isLoading && <CircularProgress color="secondary" />}
        <div className={cx({ [props.classes.hidden]: props.isLoading })}>
          {props.withLogo && (
            <img alt="DMS LOGO" src={logo} className={props.classes.logo} />
          )}

          {props.children}
        </div>
      </Card>
    </div>
  );
}

export default enhancer(CenteredCard);
